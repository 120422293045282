import { getLanguageFromPath } from '~/helpers/language'
import { useDrupalUser } from '~/stores/drupalUser'

/**
 * Redirects to the login page if the user is not logged in.
 */
export default defineNuxtRouteMiddleware((to) => {
  const drupalUser = useDrupalUser()

  if (drupalUser.isLoggedIn) {
    return navigateTo({
      name: 'user-overview___' + getLanguageFromPath(to.path),
      params: {
        id: drupalUser.id,
      },
    })
  }
})
